import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../templates/main"
import SEO from "../components/seo"
import Hero from "../components/Organisms/Hero"

const NotFoundPage = () => {
  const { banner: { childImageSharp: { fluid } } } = useStaticQuery(query)
  return (
    <Layout>
      <SEO title="404: Página não encontrada" />
      <Hero 
        title="Página não encontrada"
        description="Desculpe, esta página não existe."
        fluidImage={fluid}
      />
    </Layout>
  )
}

export const query = graphql`
  query {
    banner: file(relativePath: { eq: "banners/main-banner.jpg" }) {
    childImageSharp {
      fluid(base64Width: 1000, quality: 50) {
        base64
      }
    }
  }}
`

export default NotFoundPage
